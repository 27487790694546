<template>
  <main id="content" role="main" class="bg-light">
    <div class="position-relative overflow-hidden">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-60 text-center mx-lg-auto">
          <h1 class="mb-3">Contact Us</h1>
          <p class="lead">Get in touch and let us know how we can help.</p>
        </div>
      </div>

      <div class="container space-bottom-2">
        <div class="w-md-80 mx-auto">

          <div class="card" data-aos="fade-up" data-aos-delay="100">
            <div class="card-body">
              <form ref="benGeneralForm" />
            </div>
          </div>

        </div>
      </div>

    </div>
  </main>

</template>

<script>
export default {
  name: 'Contact',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "cad734f6-1967-44a5-8c2c-3b341600a2dc"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
  },
  methods: {
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'Contact | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/contact' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'Contact | FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/contact' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
